<template>
  <div class="order-logistics">
    <div class="header">
      <divider-title text="物流" />
    </div>
    <div class="container">
      <div class="logistics-info">
        <div class="title">产品包裹信息</div>
        <div class="order-process">
          <div class="process-item" v-for="item in orderProcess" :key="item.id">
            <div class="image-box">
              <div :class="['process-image', item.id]"></div>
            </div>
            <div class="label">{{ item.label }}</div>
          </div>
        </div>
        <div class="process-detail">
          <div class="detail-title">{{ isReceipt ? "已签收" : "未签收" }}</div>
          <div class="timeline" v-if="timeList && timeList.length">
            <div class="time-item" v-for="(item, index) in timeList" :key="index">
              <div class="date">{{ item.date }}</div>
              <div class="day">{{ item.day }}</div>
              <div>
                <div class="time" v-for="(subItem, subIndex) in item.list" :key="subIndex">
                  <span>{{ subItem.time }}</span>
                  <span>{{ subItem.label }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="timeline">暂无物流信息</div>
        </div>
      </div>
      <div class="ware-list">
        <div>订单编号：{{ orderNo }}</div>
        <div>运送单号：{{ courierinfo.awbcode }}</div>
        <div>收货地址：{{ to.city }}</div>
        <!-- <div class="ware-item" v-for="(item, index) in wareList" :key="index">
          <img :src="item.image" alt />
          <div class="ware-info">
            <span class="warename">购买商品：{{ item.wareName }}</span>
            <span>运送单号：{{ item.orderId }}</span>
            <span>收货地址：{{ item.addr }}</span>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import dividerTitle from "@/components/dividerTitle";
import { GET_LOGISTICS } from "../../service/order";
import { dateFormat } from "../../common/util";

export default {
  name: "OrderLogistics",
  components: { dividerTitle },
  data() {
    return {
      orderNo: "",
      orderProcess: [
        { label: "揽件", id: "lanjian" },
        { label: "运输", id: "yunshu" },
        { label: "派件", id: "paijian" },
        { label: "签收", id: "qianshou" }
      ],
      logisticsList: [],
      to: {},
      courierinfo: {}
      // wareList: [
      //   {
      //     wareName: "钻石1",
      //     size: "均码",
      //     image:
      //       "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      //     addr: "北京市朝阳区XXX小区xx栋1606号",
      //     orderId: 131312123132132
      //   }
      // ]
    };
  },
  computed: {
    isReceipt() {
      return this.logisticsList.some(item => +item.opCode === 80);
    },
    timeList() {
      let dateCache = {};
      let weekMap = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return this.logisticsList.reduce((arr, item) => {
        let dateInstance = new Date(item.scanDateTime);
        let date = dateFormat(dateInstance, "yyyy-MM-dd");
        if (dateCache[date] === void 0) {
          arr.push({
            date,
            day: weekMap[dateInstance.getDay()],
            list: [
              {
                time: dateFormat(dateInstance, "hh:mm:ss"),
                label: item.remark
              }
            ]
          });
          dateCache[date] = arr.length - 1;
        } else {
          arr[dateCache[date]].list.push({
            time: dateFormat(dateInstance, "hh:mm:ss"),
            label: item.remark
          });
        }
        return arr;
      }, []);
    }
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.orderNo = this.$route.query.orderNo;
    this.getOrderloggistics(this.orderId);
  },
  methods: {
    getOrderloggistics(orderid) {
      console.log(orderid);
      GET_LOGISTICS({ orderid }).then(res => {
        this.courierinfo = res.attachment.CourierInfo;
        this.to = res.attachment.to;
        this.logisticsList = res.attachment.logistics.reverse();
      });
    }
  }
};
</script>
<style lang="less" scoped>
.order-logistics {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
  }
  .container {
    padding: 20px 0 86px 0;
    .logistics-info {
      background: #fff;
      box-shadow: 0px 4px 5px 2px #d6d6d6;
      padding-bottom: 30px;
      .title {
        padding: 8px 54px 0;
        line-height: 40px;
        font-size: 16px;
        border-bottom: 1px solid #d0d0d0; /*no*/
      }
      .order-process {
        display: flex;
        padding: 40px 82px 10px;
        .process-item {
          position: relative;
          .image-box {
            display: flex;
            align-items: center;
          }
          .image-box::after {
            margin: 0 32px;
            display: block;
            content: "";
            width: 116px;
            height: 10px;
            background: url("../../assets/images/point.png");
          }
          .label {
            width: 70px;
            text-align: center;
            font-size: 16px;
            line-height: 36px;
          }
          .process-image {
            width: 70px;
            height: 70px;
            background-size: 100% 100%;
          }
          .lanjian {
            background-image: url("../../assets/images/process-1.png");
          }
          .yunshu {
            background-image: url("../../assets/images/process-2.png");
          }
          .paijian {
            background-image: url("../../assets/images/process-3.png");
          }
          .qianshou {
            background-image: url("../../assets/images/process-4.png");
          }
        }
        .process-item:last-child .image-box::after {
          display: none;
        }
      }
      .process-detail {
        margin: 0 54px;
        background: #f5f5f5;
        .detail-title {
          height: 52px;
          line-height: 52px;
          font-size: 16px;
          padding-left: 28px;
          border-bottom: 1px solid #d0d0d0; /*no*/
        }
      }
      .timeline {
        padding: 18px 44px 24px;
        .time-item {
          display: flex;
          line-height: 2;
          font-size: 12px;
          .date {
            margin-left: 44px;
            margin-right: 24px;
            white-space: nowrap;
          }
          .day {
            white-space: nowrap;
            margin-right: 16px;
          }
          .time {
            display: flex;
            span:first-child {
              margin-right: 30px;
            }
            span:last-child {
              width: 500px;
              word-break: break-all;
            }
          }
        }
      }
    }
    .ware-list {
      margin-top: 30px;
      padding: 20px 54px;
      background: #fff;
      box-shadow: 0px 4px 5px 2px #d6d6d6;
      div {
        font-size: 17px;
        line-height: 50px;
        color: #000;
      }
      // .ware-item {
      //   width: 100%;
      //   height: 240px;
      //   padding: 20px 0;
      //   display: flex;
      //   align-items: center;
      //   img {
      //     width: 200px;
      //     height: 200px;
      //     border-radius: 16px;
      //   }
      //   .ware-info {
      //     height: 200px;
      //     margin-left: 50px;
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     > span {
      //       font-size: 17px;
      //       line-height: 50px;
      //       color: #000;
      //     }
      //   }
      // }
    }
  }
}
</style>
